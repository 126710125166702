import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

import Layout from '../components/layout';
import Head from '../components/head';

import bindLegacyActions from '../legacy/main';
import onSubmit from '../legacy/validations';

import imgJourney from '../images/journey.svg';
import imgArrowDown from '../images/arrow-down.svg';
import imgSmall from '../images/small.svg';
import imgOffice from '../images/office.jpg';
import imgSpring from '../images/spring.svg';
import imgFattal from '../images/fattal.jpg';
import imgNadav from '../images/nadav.png';
import imgAviv from '../images/aviv.jpg';
import imgLinkedin from '../images/linkedin.svg';
import video from '../images/comp.mp4';

const IndexPage = () => {
  useEffect(bindLegacyActions);

  return (
    <Layout>
      <Head/>
      <section className="welcome-section section">
        <div className="logo-container">
          <img className="logo" alt="Journey Ventures" src={imgJourney} />
        </div>
        <span className="welcome-text">Leading travel tech companies on a journey to success</span>
        <video id="video" muted autoPlay loop>
          <source src={video} type="video/webm"/>
        </video>

        <div className="clouds-mob"></div>
        <div className="scroll-link">
          <span>Take a journey </span>
          <img src={imgArrowDown} alt="Take a journey"/>
        </div>
      </section>
      <section className="we-are section" id="we-are">
        <div className="we-are-text  text-block">
          <div><img className="img-for-mob" alt="" src={imgSmall}/></div>
          <p>
            Our goal is to develop a portfolio of Israeli and international companies specializing in the fields of
            tourism, travel Tech and the hotel industry that have reached an advanced stage of technological
            development.
          </p>
        </div>
        <div className="we-are-img-container sections-contents">
          <img className="we-are-img" alt="Office" src={imgOffice}/>
        </div>
      </section>
      <span className="title margin-sides title-partners">PARTNERS</span>
      <section className="partners margin-sides section">
        <div className="spring-partner sections-contents">
          <div className="container-border-right">
            <img className="partner-icon partner-icon-spring" alt="Spring Ventures" src={imgSpring}/>
            <span className="spring-text text-block">
              Our investment objective is to seek long-term capital appreciation by investing in a diversified
              portfolio, while taking advantage of technology-based platforms, global market trends and our
              expertise.
              Spring Ventures was party to the largest e-commerce transaction ever in Israel, when it sold its
              online
              commerce activities to the Azrieli Group in May 2016 for ILS 80 million (about $22,000,000). The
              main
              activities of the company are devoted to investments in the fields of Technology and the Internet,
              investing in both private and public companies.
          </span>
          </div>
        </div>
        <div className="fattal-partner sections-contents">
          <img className="partner-icon partner-icon-fattal" alt="Fattal" src={imgFattal}/>
          <span className="fattal-text text-block">
            Israel’s largest hospitality organization, with hotels distributed throughout the country.
            With more than 200 hotels in 20 countries and as owners and managers of “Leonardo”, a leading European hotel
            brand. Operated in accordance with concepts that are as innovative as they are varied, enabling
            guests to best manage their vacation time in ways that suit them best.
        </span>
        </div>
      </section>
      <span className="title margin-sides">OUR TEAM</span>
      <section className="our-team margin-sides section">
        <div className="person-wrapper sections-contents" id="js-nadav" data-id="3">
          <div className="nadav">
            <img className="person-img" src={imgNadav} alt="Nadav Fattal" data-id="3"/>
            <div className="animated-container-nadav">
              <span className="name-title">Nadav Fattal</span>
              <br/>
              <span className="position-title">VP Marketing Fattal hotels group</span>
              <div className="text-about-person text-block" data-id="3">
                Nadav has accumulated more than 10 years of
                experience focusing on digital marketing for the Fattal Group, heading the chain’s digital
                marketing
                effort and developing fruitful technological products and platforms. While working in digital
                marketing as a whole, Nadav has specialized in working with start-ups in particular and is
                experienced in developing new products and companies.
              </div>
            </div>
          </div>
        </div>
        <div className="person-wrapper sections-contents" id="js-aviv" data-id="4">
          <div className="aviv">
            <img className="person-img" src={imgAviv} alt="Aviv Refuah" data-id="4"/>
            <div className="animated-container-nadav">
              <span className="name-title">Aviv Refuah</span>
              <br/>
              <span className="position-title">Founder & CEO at Spring Venture</span>
              <div className="text-about-person text-block" data-id="4">
                A prodigy who established and directed his
                first
                company nearly two decades ago, at the age of 18, and has never slowed down, Aviv Refuah is one
                of
                Israel’s first and most experienced Tech entrepreneurs, with a proven track record in
                recognizing
                technological trends and turning them into opportunities.
                Much of his work until now has focused on e-commerce and the potential inherent in online sales
                platforms.
              </div>
            </div>
          </div>
        </div>
      </section>
      <span className="title margin-sides more-info-title">For further info, contact us:</span>
      <section className=" margin-sides section">
        <div className="form sections-contents">
          <span className="text-block form-text">Fill in your details and we'll be happy to call you:</span>
          <h5 id="error">Form isn't valid</h5>
          <h5 id="mail-error">Write correct email</h5>
          <h5 id="phone-error">Write correct phone!</h5>
          <form
            method="post"
            onSubmit={(e => onSubmit(e).then(isSuccess => isSuccess && navigate('/success/')))}
          >
            <textarea className="small-textarea" id="name" htmlFor="name" type="text" placeholder="Name" name="name"></textarea>
            <br/>
            <textarea className="small-textarea" id="phone" htmlFor="phone" type="tel" placeholder="Phone Number" name="phone"></textarea>
            <br/>
            <textarea className="small-textarea" id="mail" htmlFor="email" type="email" placeholder="Email" name="email"></textarea>
            <br/>
            <textarea id="message" htmlFor="comments" cols="30" rows="10" placeholder="Message" name="message"></textarea>
            <br/>
            <button id="btn-form" className="btn-submit" type="submit"> Submit </button>
          </form>
          <div className="throw_error"></div>
          <h5 id="error">Form isn't valid</h5>
          <h5 id="mail-error">Write correct email!</h5>
          <h5 id="phone-error">Write correct phone!</h5>
          <h5 id="success">We got your information!</h5>
        </div>
        <div className="contacts sections-contents">
        <span className="text-block contact-us-text">
          <span className="we-are-also"> We are also available at: </span>
          <a className="link-as-text" href="tel:+97235434060">+97235434060</a>
          <br/>
          94 Yigal Alon st. Tel Aviv 6789156, Israel
          <br/>
          <a className="link-as-text" href="mailto:contact@journey-ventures.com">contact@journey-ventures.com</a>
          <br/>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/journey-ventures/"><img src={imgLinkedin} alt="Linkedin"/></a>
        </span>
        </div>
      </section>
      <footer>
        © All rights reserved Journey Ventures 2022
      </footer>
    </Layout>
  );
};

export default IndexPage;
